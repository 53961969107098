import classNames from 'classnames'
import { useEffect, useState } from 'react'
import { envIsCommon } from '@/helper/env'
import { oss_svg_image_domain_address } from '@/constants/oss'
import Link from '@/components/link'
import { Button } from '@nbit/arco'

export enum WalletIntroduceEnum {
  ios = 'iPhone',
  android = 'Android',
}

function WalletComponent({ h5 = false, appInfo }: { h5?: boolean; appInfo: any }) {
  const [os, setOs] = useState('')
  let appInfoSort = [] as any
  appInfo?.forEach(item => {
    let key = item?.appTypeCd
    if (key === 'google') {
      appInfoSort[0] = item
    }
    if (key === 'android') {
      appInfoSort[1] = item
    }
    if (key === 'appstore') {
      appInfoSort[2] = item
    }
    if (key === 'ios') {
      appInfoSort[3] = item
    }
    if (key === 'windows') {
      appInfoSort[4] = item
    }
    if (key === 'mac') {
      appInfoSort[5] = item
    }
  })
  appInfo?.forEach(item => {
    let key = item?.appTypeCd
    let des = item?.description
    if (key === 'windows' && (des === 'x86 版本' || des === 'X86 Version')) {
      appInfoSort[4].downloadUrl = item.downloadUrl
      appInfoSort[4].appStatusInd = item.appStatusInd
    }

    if (key === 'windows' && (des === 'X64 版本' || des === 'X64 Version')) {
      appInfoSort[4].downloadUrl2 = item.downloadUrl
      appInfoSort[4].appStatusInd2 = item.appStatusInd
    }
    if (key === 'mac' && (des === 'M1 芯片' || des === 'M1 Version')) {
      appInfoSort[5].downloadUrl = item.downloadUrl
      appInfoSort[5].appStatusInd = item.appStatusInd
    }

    if (key === 'mac' && (des === 'Intel 64 位版本' || des === 'Intel 64 Version')) {
      appInfoSort[5].downloadUrl2 = item.downloadUrl
      appInfoSort[5].appStatusInd2 = item.appStatusInd
    }
  })

  // 这里只需要通过 set，然后通过判断即可  看情况封装成一个组件
  /**
   *区别 4 种操作系统 32，64
   */
  const distSystem = () => {
    // 获取 user agent
    const userAgent = navigator.userAgent

    // 正则匹配
    const reWindows32 = /Windows(?!.*Win64|.*x64|.*WOW64)/
    const reWindows64 = /Windows.*(Win64|x64|WOW64)/
    const reMac32 = /Macintosh(?!.*x86_64)/
    const reMac64 = /Macintosh.*(x86_64)/

    // 检查类型
    if (reWindows32.test(userAgent)) {
      setOs('Windows 32')
    } else if (reWindows64.test(userAgent)) {
      setOs('Windows 64')
    } else if (reMac32.test(userAgent)) {
      setOs('MacOS 32')
    } else if (reMac64.test(userAgent)) {
      setOs('MacOS 64')
    }

    // 打印出操作系统和位数
  }

  useEffect(() => {
    distSystem()
  }, [])

  const homeUrl = `${oss_svg_image_domain_address}IM-chat-cn/home/`

  return (
    <div className="iph-and">
      {appInfoSort?.map(item => {
        const lowerCase = item?.appTypeCd?.toLowerCase()
        let href = ''
        let imgUrl
        let isTarget = true
        let dowTitle = ''
        let disableds
        if (lowerCase === 'ios') {
          imgUrl = 'icon_index_ TestFlight.svg'
          dowTitle = 'TestFlight'
          href = item?.downloadUrl
          disableds = item?.appStatusInd === 1
        }
        if (lowerCase === 'android') {
          imgUrl = 'icon_index_Android.svg'
          dowTitle = 'Android'
          isTarget = false
          href = item?.downloadUrl
          disableds = item?.appStatusInd === 1
        }
        if (lowerCase === 'google') {
          imgUrl = 'icon_index_googlePlay.svg'
          dowTitle = 'Google'
          href = item?.downloadUrl
          disableds = item?.appStatusInd === 1
        }
        if (lowerCase === 'appstore') {
          imgUrl = 'icon_index_iPhone.svg'
          dowTitle = 'ios'
          href = item?.downloadUrl
          disableds = item?.appStatusInd === 1
        }
        if (lowerCase === 'windows') {
          isTarget = false

          imgUrl = 'icon_index_Windows.svg'
          dowTitle = 'Windows'
          disableds = !(item?.appStatusInd === 2 && item?.appStatusInd2 === 2)

          if (item?.appStatusInd === 1 && item?.appStatusInd2 === 1) {
            if (os === 'Windows 32') {
              href = item?.downloadUrl
            } else {
              href = item?.downloadUrl2
            }
          }
          if (item?.appStatusInd === 1 && item?.appStatusInd2 === 2) {
            href = item?.downloadUrl
          }
          if (item?.appStatusInd === 2 && item?.appStatusInd2 === 1) {
            href = item?.downloadUrl2
          }
        }
        if (lowerCase === 'mac') {
          isTarget = false

          imgUrl = 'icon_index_mac.svg'
          dowTitle = 'Mac'
          disableds = !(item?.appStatusInd === 2 && item?.appStatusInd2 === 2)

          if (item?.appStatusInd === 1 && item?.appStatusInd2 === 1) {
            if (os === 'MacOS 32') {
              href = item?.downloadUrl2
            } else {
              href = item?.downloadUrl
            }
          }
          if (item?.appStatusInd === 1 && item?.appStatusInd2 === 2) {
            href = item?.downloadUrl
          }
          if (item?.appStatusInd === 2 && item?.appStatusInd2 === 1) {
            href = item?.downloadUrl2
          }
        }
        return (
          <div key={lowerCase}>
            {disableds ? (
              // <Link href={href} target={isTarget}>
              <div
                className={classNames(
                  envIsCommon ? 'boxs-hover' : 'boxs-hover-pu',
                  'mr-5',
                  'boxs-w',
                  'boxs',
                  h5 && 'boxs-w-h5'
                )}
              >
                <img src={`${homeUrl}${imgUrl}`} alt="" />
              </div>
            ) : (
              // </Link>
              <Button disabled className={classNames('mr-5', 'boxs-w', 'boxs-disable')}>
                <img src={`${homeUrl}${imgUrl}`} alt="" />
              </Button>
            )}
            <div className="dow-title">{dowTitle}</div>
          </div>
        )
      })}
    </div>
  )
}

export { WalletComponent }
